import { parseBoolean, parseInteger, parseString, parseObject, parseArray, parseOpt, parseConst, parseDiscriminator, parseDiscriminatorField, parsePipe } from '../util/parse';
import { parseAttributeType } from '../types/AttributeType';
function common(x) {
    return { type: parseConst(x),
        attribute_id: parseInteger,
        changed: parseOpt(parseString) };
}
function po(typ, vals) {
    return parseObject('Attribute', Object.assign(Object.assign({}, common(typ)), vals));
}
export const parseAttribute = parseDiscriminator(parsePipe(parseDiscriminatorField('type'), parseAttributeType), { NoData: po('NoData', { value: parseConst(undefined) }),
    Data8: po('Data8', { value: parseOpt(parseInteger) }),
    Data16: po('Data16', { value: parseOpt(parseInteger) }),
    Data24: po('Data24', { value: parseOpt(parseInteger) }),
    Data32: po('Data32', { value: parseOpt(parseInteger) }),
    Data40: po('Data40', { value: parseOpt(parseInteger) }),
    Data48: po('Data48', { value: parseOpt(parseInteger) }),
    Data56: po('Data56', { value: parseOpt(parseInteger) }),
    Data64: po('Data64', { value: parseOpt(parseInteger) }),
    Bool: po('Bool', { value: parseOpt(parseBoolean) }),
    Bitmap8: po('Bitmap8', { value: parseOpt(parseInteger) }),
    Bitmap16: po('Bitmap16', { value: parseOpt(parseInteger) }),
    Bitmap24: po('Bitmap24', { value: parseOpt(parseInteger) }),
    Bitmap32: po('Bitmap32', { value: parseOpt(parseInteger) }),
    Bitmap40: po('Bitmap40', { value: parseOpt(parseInteger) }),
    Bitmap48: po('Bitmap48', { value: parseOpt(parseInteger) }),
    Bitmap56: po('Bitmap56', { value: parseOpt(parseInteger) }),
    Bitmap64: po('Bitmap64', { value: parseOpt(parseInteger) }),
    UInt8: po('UInt8', { value: parseOpt(parseInteger) }),
    UInt16: po('UInt16', { value: parseOpt(parseInteger) }),
    UInt24: po('UInt24', { value: parseOpt(parseInteger) }),
    UInt32: po('UInt32', { value: parseOpt(parseInteger) }),
    UInt40: po('UInt40', { value: parseOpt(parseInteger) }),
    UInt48: po('UInt48', { value: parseOpt(parseInteger) }),
    UInt56: po('UInt56', { value: parseOpt(parseInteger) }),
    UInt64: po('UInt64', { value: parseOpt(parseInteger) }),
    Int8: po('Int8', { value: parseOpt(parseInteger) }),
    Int16: po('Int16', { value: parseOpt(parseInteger) }),
    Int24: po('Int24', { value: parseOpt(parseInteger) }),
    Int32: po('Int32', { value: parseOpt(parseInteger) }),
    Int40: po('Int40', { value: parseOpt(parseInteger) }),
    Int48: po('Int48', { value: parseOpt(parseInteger) }),
    Int56: po('Int56', { value: parseOpt(parseInteger) }),
    Int64: po('Int64', { value: parseOpt(parseInteger) }),
    Enum8: po('Enum8', { value: parseOpt(parseInteger) }),
    Enum16: po('Enum16', { value: parseOpt(parseInteger) }),
    FloatSemi: po('FloatSemi', { value: parseOpt(parseString) }),
    FloatSingle: po('FloatSingle', { value: parseOpt(parseString) }),
    FloatDouble: po('FloatDouble', { value: parseOpt(parseString) }),
    OctetStr: po('OctetStr', { value: parseOpt(parseArray(parseInteger)) }),
    CharStr: po('CharStr', { value: parseOpt(parseString) }),
    LongOctetStr: po('LongOctetStr', { value: parseOpt(parseArray(parseInteger)) }),
    LongCharStr: po('CharStr', { value: parseOpt(parseString) }),
    Array: po('Array', { value: parseConst(undefined) }),
    Struct: po('Struct', { value: parseConst(undefined) }),
    Set: po('Set', { value: parseConst(undefined) }),
    Bag: po('Bag', { value: parseConst(undefined) }),
    TimeOfDay: po('TimeOfDay', { value: parseOpt(parseString) }),
    Date: po('Date', { value: parseOpt(parseString) }),
    UTCTime: po('UTCTime', { value: parseOpt(parseInteger) }),
    ClusterID: po('ClusterID', { value: parseOpt(parseInteger) }),
    AttributeID: po('AttributeID', { value: parseOpt(parseInteger) }),
    BACnetOID: po('BACnetOID', { value: parseOpt(parseString) }),
    IEEEAddr: po('IEEEAddr', { value: parseOpt(parseString) }),
    Security128: po('Security128', { value: parseOpt(parseArray(parseInteger)) }),
    Unknown: po('Security128', { value: parseConst(undefined) }),
});
