import { createSlice } from '@reduxjs/toolkit';
export const zigbeeDeviceInfoSlice = createSlice({
    name: 'zigbee_device_info',
    initialState: new Map(),
    reducers: {
        zigbeeDeviceInfosCleared: s => { s.clear(); },
        zigbeeDeviceInfoUpdated: (s, action) => { s.set(action.payload.id, action.payload.device_info); },
        zigbeeDeviceInfoDeleted: (s, action) => { s.delete(action.payload); },
    }
});
export const { zigbeeDeviceInfosCleared, zigbeeDeviceInfoUpdated, zigbeeDeviceInfoDeleted } = zigbeeDeviceInfoSlice.actions;
export default zigbeeDeviceInfoSlice.reducer;
