import { createSlice } from '@reduxjs/toolkit';
export const zigbeeNodeBindingTableSlice = createSlice({
    name: 'zigbee_node_binding_table',
    initialState: new Map(),
    reducers: {
        zigbeeNodeBindingTableCleared: s => { s.clear(); },
        zigbeeNodeBindingTableUpdated: (s, action) => {
            s.set(action.payload.node_id, action.payload.binding_table);
        },
        zigbeeNodeBindingTableDeleted: (s, action) => { s.delete(action.payload); },
    }
});
export const { zigbeeNodeBindingTableCleared, zigbeeNodeBindingTableUpdated, zigbeeNodeBindingTableDeleted } = zigbeeNodeBindingTableSlice.actions;
export default zigbeeNodeBindingTableSlice.reducer;
