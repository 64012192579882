import { parseString, parseObject, parseDiscriminator, parseConst, parseDiscriminatorField } from '../util/parse';
import { parseSun } from '../types/Sun';
import { parseTime } from '../types/Time';
export const parseScheduleTime = parseDiscriminator(parseDiscriminatorField('kind'), { 'cron': parseObject('ScheduleTimeCron', { kind: parseConst('cron'),
        cron: parseString }),
    'time': parseObject('ScheduleTimeTime', { kind: parseConst('time'),
        time: parseTime }),
    'sun': parseObject('ScheduleTimeSun', { kind: parseConst('sun'),
        sun: parseSun })
});
