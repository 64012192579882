import { parseInteger, parseString, parseObject, parseNullOpt, parseTransform } from '../util/parse';
import { parseBindingTable } from '../types/Zigbee/BindingTable';
import { parseNeighbors } from '../types/Zigbee/Neighbors';
import { parseEndpointMap } from '../types/endpoint';
export const parseZigbeeNode = parseTransform(parseObject('ZigbeeNode', {
    id: parseInteger,
    ieee: parseNullOpt(parseInteger),
    ieee_hex: parseNullOpt(parseString),
    last_seen: parseString,
    manufacturer_code: parseNullOpt(parseInteger),
    manufacturer_name: parseNullOpt(parseString),
    nwk: parseNullOpt(parseInteger),
    binding_table: parseBindingTable,
    neighbors: parseNeighbors,
    endpoints: parseEndpointMap,
}), o => ({
    id: o.id,
    ieee: o.ieee,
    ieee_hex: o.ieee_hex,
    last_seen: o.last_seen,
    manufacturer_code: o.manufacturer_code,
    manufacturer_name: o.manufacturer_name,
    nwk: o.nwk,
    moved_to_own_slice_binding_table: o.binding_table,
    moved_to_own_slice_neighbors: o.neighbors,
    endpoints: o.endpoints,
}));
