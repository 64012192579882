import { parseInteger, parseBoolean, parseString, parseHex16Bits, parseNullOpt, parseObject } from '../util/parse';
export const parseNodeDescriptor = parseObject('NodeDescriptor', {
    id: parseInteger,
    allocate_addr: parseBoolean,
    backup_binding_table_cache: parseBoolean,
    backup_discovery_cache: parseBoolean,
    backup_trust_center: parseBoolean,
    can_be_coordinator: parseBoolean,
    complex_descriptor: parseBoolean,
    created: parseString,
    device_type_full: parseBoolean,
    extended_active_endpoints_available: parseBoolean,
    extended_simple_descriptor_available: parseBoolean,
    frequency_band_2400: parseBoolean,
    frequency_band_868: parseBoolean,
    frequency_band_902: parseBoolean,
    logical_type: parseString,
    manufacturer: parseHex16Bits,
    manufacturer_name: parseNullOpt(parseString),
    maximum_buffer_size: parseInteger,
    maximum_incoming_transfer_size: parseInteger,
    maximum_outgoing_transfer_size: parseInteger,
    network_manager: parseBoolean,
    power_source_mains: parseBoolean,
    primary_binding_table_cache: parseBoolean,
    primary_discovery_cache: parseBoolean,
    primary_trust_center: parseBoolean,
    reciever_on_when_idle: parseBoolean,
    security_capable: parseBoolean,
    stack_compliance_revision: parseInteger,
    user_descriptor: parseBoolean,
});
