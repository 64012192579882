import { parseInteger, parseString, parseObject, parseOpt, parseArray, parseTransform } from '../util/parse';
import { parseZigbeeGUI } from '../types/ZigbeeGUI';
import { parseGroups } from '../types/Zigbee/Groups';
export const parseEndpoint = parseObject('Endpoint', {
    clusters: parseArray(parseInteger),
    device: parseInteger,
    device_name: parseString,
    device_version: parseInteger,
    eid: parseInteger,
    endpoint: parseInteger,
    gui: parseZigbeeGUI,
    input_clusters: parseArray(parseInteger),
    output_clusters: parseArray(parseInteger),
    groups: parseOpt(parseGroups),
    profile: parseInteger,
    profile_name: parseString,
    zigbee_node_id: parseInteger,
});
export const parseEndpointMap = parseTransform(parseArray(parseEndpoint), es => {
    var m = new Map();
    for (const e of es) {
        m.set(e.endpoint, e);
    }
    return m;
});
