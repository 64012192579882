import { parseInteger, parseString, parseArray, parseEnum, parseObject, parsePipe, parseDiscriminator, parseDiscriminatorField, parseConst, parseOr, parseTransform, parseLiteral } from '../util/parse';
import { parseEndpoint } from '../types/endpoint';
import { parseTransmitterKind } from '../types/transmitter';
import { parseNexaCode } from '../types/NexaCode';
export const parseDeviceProtocolKind = parseEnum(['nexa receiver', 'nexa transmitter', 'zigbee', 'hasta receiver', 'hasta transmitter', 'mandolyn']);
export function toNexaTransmitterKind(t) {
    switch (t) {
        case 'dim': return 'dimmer';
        case 'onoff': return 'onoff';
        case 'rollershade': throw new Error('TransmitterKind "rollershade" can not be converted to NexaTransmitterKind');
    }
}
export function fromNexaTransmitterKind(t) {
    switch (t) {
        case 'dimmer': return 'dim';
        case 'onoff': return 'onoff';
    }
}
export const parseNexaTransmitterKind = parseEnum(['onoff', 'dimmer']);
export function toNexaReceiverKind(t) {
    switch (t) {
        case 'dim': return 'dimmer';
        case 'onoff': return 'onoff';
        case 'rollershade': throw new Error('TransmitterKind "rollershade" can not be converted to NexaReceiverKind');
    }
}
export function fromNexaReceiverKind(t) {
    switch (t) {
        case 'dimmer': return 'dim';
        case 'onoff': return 'onoff';
    }
}
export const parseNexaReceiverKind = parseEnum(['onoff', 'dimmer']);
export const parseDeviceProtocol = parseDiscriminator(parsePipe(parseDiscriminatorField('name'), parseDeviceProtocolKind), { 'nexa receiver': parseObject('DeviceProtocol', { name: parseConst('nexa receiver'),
        kind: parseNexaReceiverKind,
        codes: parseArray(parseNexaCode) }),
    'nexa transmitter': parseObject('DeviceProtocol', { name: parseConst('nexa transmitter'),
        kind: parseNexaTransmitterKind,
        code: parseInteger,
        unit: parseInteger }),
    'hasta receiver': parseObject('DeviceProtocol', { name: parseConst('hasta receiver'),
        codes: parseArray(parseNexaCode) }),
    'hasta transmitter': parseObject('DeviceProtocol', { name: parseConst('hasta transmitter'),
        code: parseInteger,
        unit: parseInteger }),
    'mandolyn': parseObject('DeviceProtocol', { name: parseConst('mandolyn'),
        code: parseString }),
    'zigbee': parseObject('DeviceProtocol', { name: parseConst('zigbee'),
        endpoint: parseEndpoint })
});
export const parseDevice = parseObject('Device', {
    id: parseInteger,
    title: parseString,
    comment: parseString,
    protocol: parseDeviceProtocol,
    transmitter_kind: parseOr(parseTransform(parseLiteral(''), _ => undefined), parseTransmitterKind),
});
