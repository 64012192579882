import { app } from '../reducers/app';
import definitions from '../features/definitions/definitionsSlice';
import { device_states } from '../reducers/device_states';
import { deviceStatesZigbee } from '../reducers/DeviceStatesZigbee';
import { edit_macro_dialog } from '../reducers/edit_macro_dialog';
import { edit_outlet_dialog } from '../reducers/edit_outlet_dialog';
import { edit_panel_group_dialog } from '../reducers/edit_panel_group_dialog';
import { edit_panel_transmitter_dialog } from '../reducers/edit_panel_transmitter_dialog';
import { edit_schedule_dialog } from '../reducers/edit_schedule_dialog';
import { edit_transmitter_dialog } from '../reducers/edit_transmitter_dialog';
import { sensors } from '../reducers/sensors';
import { throttle } from '../reducers/throttle';
import zigbee_device_info from '../features/zigbee_device_info/zigbeeDeviceInfoSlice';
export const reducers = {
    sensors,
    edit_outlet_dialog,
    edit_panel_group_dialog,
    edit_panel_transmitter_dialog,
    edit_schedule_dialog,
    edit_transmitter_dialog,
    edit_macro_dialog,
    app,
    definitions,
    device_states,
    deviceStatesZigbee,
    throttle,
    zigbee_device_info,
};
