import { createSlice } from '@reduxjs/toolkit';
export const zigbeeNodeNeighborsSlice = createSlice({
    name: 'zigbee_node_neighbors',
    initialState: new Map(),
    reducers: {
        zigbeeNodeNeighborsCleared: s => { s.clear(); },
        zigbeeNodeNeighborUpdated: (s, action) => {
            s.set(action.payload.node_id, action.payload.neighbors);
        },
        zigbeeNodeNeighborDeleted: (s, action) => { s.delete(action.payload); },
    }
});
export const { zigbeeNodeNeighborsCleared, zigbeeNodeNeighborUpdated, zigbeeNodeNeighborDeleted } = zigbeeNodeNeighborsSlice.actions;
export default zigbeeNodeNeighborsSlice.reducer;
