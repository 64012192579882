import React from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { ConfigZigbeeEndpoint } from '../components/ConfigZigbeeEndpoint';
import { ZigbeeNodeDescriptor } from '../components/ZigbeeNodeDescriptor';
import { ZigbeeNodeBindingTable } from '../components/ZigbeeNodeBindingTable';
import { ZigbeeNodeNeighbors } from '../components/ZigbeeNodeNeighbors';
import { fetchZigbeeNodeDescriptor } from '../actions/FetchZigbeeNodeDescriptor';
import { fetchZigbeeBindingTable } from '../actions/FetchZigbeeBindingTable';
import { fetchZigbeeNodeNeighbors } from '../actions/FetchZigbeeNodeNeighbors';
import { fetchZigbeeSimpleDescriptors } from '../actions/FetchZigbeeSimpleDescriptors';
import { createZigbeeBinding } from '../actions/CreateZigbeeBinding';
import { deleteZigbeeBinding } from '../actions/DeleteZigbeeBinding';
import { getManufacturerName } from '../features/zigbee_node_descriptors/util';
export const ConfigZigbeeNode = (props) => {
    const dispatch = useAppDispatch();
    const zigbee_nodes = useAppSelector(state => state.definitions.zigbee_nodes);
    const zigbee_node_descriptors = useAppSelector(state => state.definitions.zigbee_node_descriptors);
    const zigbee_node_neighbors = useAppSelector(state => state.definitions.zigbee_node_neighbors.get(props.node_id));
    const binding_table = useAppSelector(state => state.definitions.zigbee_node_binding_table.get(props.node_id));
    const node = zigbee_nodes.get(props.node_id);
    if (node == undefined) {
        return null;
    }
    const nwk = node.nwk == null ? '?' : '0x' + node.nwk.toString(16).padStart(4, '0');
    // old versions don't have ieee_hex. we can phase out usage of node.ieee after a while
    const ieee = node.ieee_hex != null ? node.ieee_hex : (node.ieee == null ? null : (node.ieee.toString(16).padStart(16, '0')));
    const ieeeStr = ieee == null ? '?' : ('0x' + ieee);
    const node_descriptor = zigbee_node_descriptors.get(node.id);
    const manufacturer = node_descriptor ? node_descriptor.manufacturer : null;
    const manufacturer_name = getManufacturerName(node_descriptor);
    const node_endpoints = Array.from(node.endpoints.keys());
    const manufacturer_info = manufacturer_name !== null && manufacturer_name !== void 0 ? manufacturer_name : (manufacturer ? ('0x' + manufacturer.toString(16)) : null);
    const onFetchNodeDescriptor = (event) => {
        event.preventDefault();
        dispatch(fetchZigbeeNodeDescriptor(node.id));
    };
    const onFetchSimpleDescriptors = (event) => {
        event.preventDefault();
        dispatch(fetchZigbeeSimpleDescriptors(node.id));
    };
    const onFetchBindingTable = (event) => {
        event.preventDefault();
        dispatch(fetchZigbeeBindingTable(node.id));
    };
    const onFetchNodeNeighbors = (event) => {
        event.preventDefault();
        dispatch(fetchZigbeeNodeNeighbors(node.id));
    };
    const onBind = (btItem) => {
        dispatch(createZigbeeBinding(node.id, btItem));
    };
    const onUnbind = (btItem) => {
        dispatch(deleteZigbeeBinding(node.id, btItem));
    };
    var endpoints = [];
    for (const ep of node_endpoints) {
        endpoints.push(React.createElement(ConfigZigbeeEndpoint, { key: props.node_id + '_' + ep, node: props.node_id, endpoint: ep }));
    }
    return (React.createElement("div", { key: props.node_id, style: { padding: '10px 10px', borderTop: '3px solid #ddd' } },
        React.createElement("div", null,
            "NWK=",
            nwk,
            " IEEE=",
            ieeeStr,
            " manufacturer=",
            manufacturer_info),
        React.createElement(ZigbeeNodeDescriptor, { node_descriptor: node_descriptor, onFetchNodeDescriptor: onFetchNodeDescriptor }),
        endpoints,
        React.createElement(ZigbeeNodeBindingTable, { bindingtable: binding_table, ieee: ieee, onFetchBindingTable: onFetchBindingTable, onBind: onBind, onUnbind: onUnbind }),
        React.createElement(ZigbeeNodeNeighbors, { neighbors: zigbee_node_neighbors, onFetchNodeNeighbors: onFetchNodeNeighbors }),
        React.createElement("div", { style: { borderTop: '3px solid #ddd' } },
            React.createElement("form", { onSubmit: onFetchSimpleDescriptors, style: { padding: '10px 10px' } },
                React.createElement("button", { type: "submit" }, "Fetch simple descriptors")))));
};
