import { UPDATE_DEVICE_STATES } from '../actions/UpdateDeviceStates';
import { DEVICE_STATE_SET_ZIGBEE_ATTRIBUTE } from '../actions/DeviceStateSetZigbeeAttribute';
export function deviceStatesZigbee(state = new Map(), action) {
    var _a, _b;
    switch (action.type) {
        case UPDATE_DEVICE_STATES: {
            let o = new Map();
            let changed = false;
            for (const [id, actionstate] of Object.entries(action.states)) {
                let newItem;
                if (Object.prototype.hasOwnProperty.call(actionstate, 'state')) {
                    // not zigbee
                    continue;
                }
                else if (Object.prototype.hasOwnProperty.call(actionstate, 'temp')) {
                    // not zigbee
                    continue;
                }
                else {
                    // it's a zigbee object
                    newItem = new Map();
                    for (const [cluster_id, o2] of Object.entries(actionstate)) {
                        let amap = new Map();
                        for (const [attribute_id, o3] of Object.entries(o2)) {
                            amap.set(parseInt(attribute_id), new Map(Object.entries(o3)));
                        }
                        newItem.set(parseInt(cluster_id), amap);
                    }
                }
                // reuse old values in the map as much as we can so we don't trigger any renders
                // unless something has changed.
                const oldItem = state.get(parseInt(id));
                if (oldItem == undefined || JSON.stringify(oldItem) != JSON.stringify(newItem)) {
                    changed = true;
                    o.set(parseInt(id), newItem);
                }
                else {
                    o.set(parseInt(id), oldItem);
                }
            }
            return changed ? o : state;
        }
        case DEVICE_STATE_SET_ZIGBEE_ATTRIBUTE: {
            const device_id = action.device_id;
            const cluster_id = action.cluster_id;
            const attribute_id = action.attribute_id;
            const newValue = action.value;
            const newTs = action.timestamp;
            const oldItem = (_b = (_a = state.get(device_id)) === null || _a === void 0 ? void 0 : _a.get(cluster_id)) === null || _b === void 0 ? void 0 : _b.get(attribute_id);
            const newItem = new Map([['ts', newTs], ['value', newValue]]);
            if (oldItem == undefined || JSON.stringify(oldItem) != JSON.stringify(newItem)) {
                let new_map = new Map(state.entries());
                const old_device = state.get(device_id);
                let new_device;
                if (old_device != undefined) {
                    new_device = new Map(old_device);
                }
                else {
                    new_device = new Map();
                }
                new_map.set(device_id, new_device);
                let new_cluster;
                const old_cluster = new_device.get(cluster_id);
                if (old_cluster != undefined) {
                    new_cluster = new Map(old_cluster);
                }
                else {
                    new_cluster = new Map();
                }
                new_device.set(cluster_id, new_cluster);
                let new_attribute;
                const old_attribute = new_cluster.get(attribute_id);
                if (old_attribute != undefined) {
                    new_attribute = new Map(old_attribute);
                }
                else {
                    new_attribute = new Map();
                }
                new_cluster.set(attribute_id, new_attribute);
                new_attribute.set('value', newValue);
                new_attribute.set('ts', newTs);
                return new_map;
            }
            else {
                return state;
            }
        }
        default:
            return state;
    }
}
