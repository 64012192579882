import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { thunk } from 'redux-thunk';
import { createSelector } from 'reselect';
import { UPDATE_DEVICE_STATES } from '../actions/UpdateDeviceStates';
import { reducers } from '../reducers/index';
let middlewares = [
    thunk
];
if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
        predicate: (getState, action) => {
            var _a, _b;
            const log_redux_state_changes = ((_a = localStorage.getItem('log_redux_state_changes')) !== null && _a !== void 0 ? _a : '0') == '1';
            const log_device_state_changes = ((_b = localStorage.getItem('log_device_state_changes')) !== null && _b !== void 0 ? _b : '0') == '1';
            return log_redux_state_changes && (log_device_state_changes || action.type !== UPDATE_DEVICE_STATES);
        }
    });
    middlewares.push(logger);
}
function reducer() {
    return combineReducers(Object.assign({}, reducers));
}
export const store = createStore(reducer(), {}, applyMiddleware(...middlewares));
export const createAppSelector = createSelector.withTypes();
