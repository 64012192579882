import { parseBoolean, parseInteger, parseString, parseObject } from '../util/parse';
import { parseScheduleTime } from '../types/ScheduleTime';
export const parseSchedule = parseObject('Schedule', {
    id: parseInteger,
    active: parseBoolean,
    device_id: parseInteger,
    dim_level: parseInteger,
    next_run: parseString,
    operation: parseString,
    transmitter_id: parseInteger,
    time: parseScheduleTime,
});
