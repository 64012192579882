import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../hooks';
import { clusterNameAndHex } from '../zigbee/cluster';
import { attributeNameOrHex } from '../zigbee/attribute';
import { fetchZigbeeNodeCluster } from '../actions/FetchZigbeeNodeCluster';
import { fetchZigbeeAttributeValue } from '../actions/FetchZigbeeAttributeValue';
import { Dialog } from '../components/DialogOld';
import { showDialog } from '../actions/ShowDialog';
const DialogEditAttribute = (props) => {
    var _a, _b, _c, _d;
    const dispatch = useAppDispatch();
    const zigbee_device_info = useAppSelector(state => state.zigbee_device_info);
    const deviceInfo = zigbee_device_info.get(props.nid);
    if (props.data != undefined) {
        const { aid, cid } = props.data;
        const attribute = (_c = (_b = (_a = deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.attributes) === null || _a === void 0 ? void 0 : _a.get(props.eid)) === null || _b === void 0 ? void 0 : _b.get(cid)) === null || _c === void 0 ? void 0 : _c.get(aid);
        if (attribute != undefined) {
            const name = attributeNameOrHex(cid, aid);
            const fetchAttributeData = () => {
                dispatch(fetchZigbeeAttributeValue(props.nid, props.eid, cid, aid));
            };
            return (React.createElement(Dialog, { id: props.id, title: name },
                React.createElement("div", null,
                    "Value: ",
                    renderAttributeValue(attribute)),
                React.createElement("div", null,
                    "Updated at: ", (_d = attribute === null || attribute === void 0 ? void 0 : attribute.changed) !== null && _d !== void 0 ? _d : '-'),
                React.createElement("div", null,
                    "Attribute ID: ",
                    '0x' + aid.toString(16).padStart(4, '0')),
                React.createElement("br", null),
                React.createElement("button", { type: "button", className: "lw", onClick: fetchAttributeData }, "H\u00E4mta v\u00E4rde fr\u00E5n enheten")));
        }
    }
};
function renderAttributeValue(a) {
    if (typeof a.value == 'undefined') {
        return '?';
    }
    else if (a.type == 'Bool') {
        return a.value ? '\u2705' : '\u274C';
    }
    else {
        return a.value.toString();
    }
}
const Cluster = (props) => {
    var rows = [];
    for (const a of props.attributes.values()) {
        const name = attributeNameOrHex(props.cluster, a.attribute_id);
        const value = renderAttributeValue(a);
        rows.push(React.createElement("tr", { key: a.attribute_id, onClick: () => props.onOpenDialog(a.attribute_id) },
            React.createElement("td", null, name),
            React.createElement("td", null, a.type),
            React.createElement("td", null, value)));
    }
    return (React.createElement("div", { key: props.cluster },
        React.createElement("h3", null,
            "Cluster ",
            clusterNameAndHex(props.cluster),
            "\u00A0\u00A0",
            React.createElement("button", { type: "button", onClick: () => props.onFetchCluster(props.cluster) }, "\uD83D\uDDD8")),
        React.createElement("table", null,
            React.createElement("tbody", null, rows))));
};
export const ConfigZigbeeEndpointAttributes = (props) => {
    var _a;
    const dispatch = useAppDispatch();
    const [dialogData, setDialogData] = useState();
    const zigbee_device_info = useAppSelector(state => state.zigbee_device_info);
    const deviceInfo = zigbee_device_info.get(props.node);
    const attributes = deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.attributes.get(props.endpoint);
    let clusters = new Set();
    let endpoint = deviceInfo === null || deviceInfo === void 0 ? void 0 : deviceInfo.endpoints.get(props.endpoint);
    if (endpoint != undefined) {
        for (const c of endpoint.input_clusters) {
            clusters.add(c);
        }
        for (const c of endpoint.output_clusters) {
            clusters.add(c);
        }
    }
    let items = [];
    let sorted_clusters = Array.from(clusters);
    sorted_clusters.sort();
    const dialogId = 'dialog-edit-attribute-' + props.endpoint;
    for (const c of sorted_clusters) {
        const attr = (_a = attributes === null || attributes === void 0 ? void 0 : attributes.get(c)) !== null && _a !== void 0 ? _a : new Map();
        const openDialog = (attribute_id) => {
            const attribute_value = attr.get(attribute_id);
            if (attribute_value != undefined) {
                setDialogData({ cid: c, aid: attribute_id });
                dispatch(showDialog(dialogId));
            }
        };
        items.push(React.createElement(Cluster, { key: c, cluster: c, attributes: attr, onFetchCluster: () => dispatch(fetchZigbeeNodeCluster(props.node, props.endpoint, c)), onOpenDialog: openDialog }));
    }
    return (React.createElement("div", null,
        React.createElement(DialogEditAttribute, { id: dialogId, key: props.endpoint, nid: props.node, eid: props.endpoint, data: dialogData }),
        items));
};
