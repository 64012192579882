import { parseInteger, parseString, parseEnum, parseObject } from '../util/parse';
export const parseTransmitterKind = parseEnum(['onoff', 'dim', 'rollershade']);
export const parseTransmitter = parseObject('Transmitter', {
    id: parseInteger,
    title: parseString,
    comment: parseString,
    kind: parseTransmitterKind,
    code: parseInteger,
    unit: parseInteger,
});
export function transmitterKindLabel(transmitter) {
    switch (transmitter.kind) {
        case 'onoff': return 'På/Av';
        case 'dim': return 'Dimmer';
        case 'rollershade': return 'Rullgardin';
        default: return '';
    }
}
