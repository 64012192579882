import Ajax from '../util/ajax';
import { fetchZigbeeBindingTable } from './FetchZigbeeBindingTable';
export function createZigbeeBinding(node_id, btItem) {
    return dispatch => {
        const ajax = new Ajax(dispatch, true);
        const url = '/zigbee/' + node_id + '/bindingtable';
        ajax.post(url, btItem, data => {
            if (data) {
                dispatch(fetchZigbeeBindingTable(node_id));
            }
        });
    };
}
