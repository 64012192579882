export const DEVICE_STATE_SET_ZIGBEE_ATTRIBUTE = 'DEVICE_STATE_SET_ZIGBEE_ATTRIBUTE';
function now() {
    const d = new Date();
    d.setMilliseconds(0);
    return d.toISOString().replace(/\.\d+/, '');
}
export function deviceStateSetZigbeeAttribute(device_id, cluster_id, attribute_id, value, timestamp) {
    return {
        type: DEVICE_STATE_SET_ZIGBEE_ATTRIBUTE,
        device_id,
        cluster_id,
        attribute_id,
        value,
        timestamp: timestamp !== null && timestamp !== void 0 ? timestamp : now()
    };
}
