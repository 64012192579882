import { parseObjectOrNull, parseArray, parseDate } from '../../util/parse';
import { parseGroup } from './Group';
/*
export const parseGroups: Parser<Groups | undefined> =
    parseObjectOrNull('Neighbors',  part => {
        const fetched_ts = part.req('fetched_ts', parseDate);
        const items = part.req('items', parseArray(parseGroup));
        return { fetched_ts, items };
    });
*/
export const parseGroups = parseObjectOrNull('Neighbors', {
    fetched_ts: parseDate,
    items: parseArray(parseGroup)
});
