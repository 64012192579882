import { parseInteger, parseString, parseEnum, parseArray, parseObject } from '../util/parse';
export const parseOutletKind = parseEnum(['onoff', 'dim', 'rollershade']);
export const parseOutletCode = parseObject('OutletCode', {
    code: parseInteger,
    unit: parseInteger,
});
export const parseOutlet = parseObject('Outlet', {
    id: parseInteger,
    title: parseString,
    kind: parseOutletKind,
    codes: parseArray(parseOutletCode),
    comment: parseString,
});
