import { parseInteger, parseString, parseBoolean, parseObject, parseArray } from '../util/parse';
export const parseMacroItem = parseObject('MacroItem', {
    device_id: parseInteger,
    operation: parseString,
    transmitter_id: parseInteger,
});
export const parseMacro = parseObject('Macro', {
    id: parseInteger,
    title: parseString,
    active: parseBoolean,
    items: parseArray(parseMacroItem),
});
