import { parseEnum, parseTransform, parseOr } from '../util/parse';
const parseBackwardType = parseEnum(['-', 'bit', '16bit', '24bit', '32bit', '40bit', '48bit', '56bit', '64bit']);
const parseBackward = parseTransform(parseBackwardType, s => {
    switch (s) {
        case '-': return 'NoData';
        case 'bit': return 'Data8';
        case '16bit': return 'Data16';
        case '24bit': return 'Data24';
        case '32bit': return 'Data32';
        case '40bit': return 'Data40';
        case '48bit': return 'Data48';
        case '56bit': return 'Data56';
        case '64bit': return 'Data64';
    }
});
const parseRealAttributeType = parseEnum([
    'NoData',
    'Data8', 'Data16', 'Data24', 'Data32', 'Data40', 'Data48', 'Data56', 'Data64',
    'Bool',
    'Bitmap8', 'Bitmap16', 'Bitmap24', 'Bitmap32', 'Bitmap40', 'Bitmap48', 'Bitmap56', 'Bitmap64',
    'UInt8', 'UInt16', 'UInt24', 'UInt32', 'UInt40', 'UInt48', 'UInt56', 'UInt64',
    'Int8', 'Int16', 'Int24', 'Int32', 'Int40', 'Int48', 'Int56', 'Int64',
    'Enum8', 'Enum16',
    'FloatSemi', 'FloatSingle', 'FloatDouble',
    'OctetStr', 'CharStr', 'LongOctetStr', 'LongCharStr',
    'Array', 'Struct', 'Set', 'Bag',
    'TimeOfDay', 'Date', 'UTCTime',
    'ClusterID', 'AttributeID',
    'BACnetOID', 'IEEEAddr', 'Security128',
    'Unknown'
]);
export const parseAttributeType = parseOr(parseRealAttributeType, parseBackward);
