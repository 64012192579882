import { parseObjectOrNull, parseArray, parseDate } from '../../util/parse';
import { parseNeighbor } from './Neighbor';
/*
export const parseNeighbors: Parser<Neighbors | undefined> =
    parseObjectOrNull('Neighbors',  part => {
        const fetched_ts = part.req('fetched_ts', parseDate);
        const items = part.req('items', parseArray(parseNeighbor));
        return { fetched_ts, items };
    });
*/
export const parseNeighbors = parseObjectOrNull('Neighbors', {
    fetched_ts: parseDate,
    items: parseArray(parseNeighbor)
});
