import Ajax from '../util/ajax';
import { zigbeeDeviceInfoUpdated } from '../features/zigbee_device_info/zigbeeDeviceInfoSlice';
import { parse } from '../util/parse';
import { parseZigbeeDeviceInfo } from '../types/ZigbeeDeviceInfo';
export function fetchZigbeeDeviceInfo(zigbee_node_id) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/zigbee/' + zigbee_node_id + '/all';
        ajax.get(url, undefined, data => {
            const device_info = parse(parseZigbeeDeviceInfo, data);
            dispatch(zigbeeDeviceInfoUpdated({ id: zigbee_node_id, device_info }));
        });
    };
}
