import Ajax from '../util/ajax';
import { parse } from '../util/parse';
import { parseBindingTable } from '../types/Zigbee/BindingTable';
import { zigbeeNodeBindingTableUpdated } from '../features/zigbee_node_binding_table/zigbeeNodeBindingTableSlice';
export function fetchZigbeeBindingTable(zigbee_node_id) {
    return dispatch => {
        const ajax = new Ajax(dispatch);
        const url = '/zigbee/' + zigbee_node_id + '/bindingtable';
        ajax.get(url, undefined, data => {
            const bt = parse(parseBindingTable, data);
            if (bt !== undefined) {
                dispatch(zigbeeNodeBindingTableUpdated({ node_id: zigbee_node_id, binding_table: bt }));
            }
        });
    };
}
