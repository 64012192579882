import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
export const DeviceLink = props => {
    const devices = useAppSelector(state => state.definitions.devices);
    const device = devices.get(props.device_id);
    if (device == undefined) {
        return (React.createElement("div", null, "Unknown device"));
    }
    else {
        return React.createElement(Link, { to: '/device/' + device.id, style: { color: 'black' } }, device.title);
    }
};
