import React from 'react';
import { useAppSelector } from '../hooks';
export const ConfigSensors = () => {
    const sensors = useAppSelector(state => state.sensors);
    const devices = useAppSelector(state => state.definitions.devices);
    let rows = [];
    for (const deviceState of sensors.values()) {
        const device = devices.get(deviceState.id);
        if (device) {
            rows.push(React.createElement("div", { key: deviceState.id },
                React.createElement("div", null, device.title),
                React.createElement("div", null, device.protocol.code)));
        }
    }
    return (React.createElement("div", { className: "page-container" },
        React.createElement("div", { className: "config-rows config-sensors" }, rows)));
};
