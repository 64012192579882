export class Parser {
}
class ParseContext {
    constructor(s = '') {
        this.str = s;
    }
    addClass(classname) {
        return new ParseContext(this.str + '{' + classname + '}');
    }
    addClassField(field) {
        return new ParseContext(this.str + '.' + field);
    }
    addArrayIndex(idx) {
        return new ParseContext(this.str + '[' + idx + ']');
    }
}
class ParseError extends Error {
    constructor(ctx, message) {
        super(message); // 'Error' breaks prototype chain here
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.ctx = ctx;
    }
}
class ParserError extends Parser {
    constructor(err) {
        super();
        this.err = err;
    }
    parse(ctx, _input) {
        throw new ParseError(ctx, this.err);
    }
    getType() { return 'void'; }
}
export function parseError(err) {
    return new ParserError(err);
}
class ParserTransform extends Parser {
    constructor(parser, trans) {
        super();
        this.parser = parser;
        this.trans = trans;
    }
    parse(ctx, v) {
        const a = this.parser.parse(ctx, v);
        return this.trans(a);
    }
    getType() { return this.parser.getType(); }
}
export function parseTransform(parser, trans) {
    return new ParserTransform(parser, trans);
}
class ParserConst extends Parser {
    constructor(constValue) {
        super();
        this.constValue = constValue;
    }
    parse(_ctx, _input) { return this.constValue; }
    getType() { return 'const'; }
}
export function parseConst(constValue) {
    return new ParserConst(constValue);
}
class ParserUnknown extends Parser {
    parse(ctx, v) { return v; }
    getType() { return 'unknown'; }
}
export const parseUnknown = new ParserUnknown();
class ParserUndefined extends Parser {
    parse(ctx, v) {
        if (v !== undefined) {
            throw new ParseError(ctx, 'expected undefined, got ' + typeof (v) + ' with value ' + v);
        }
        return undefined;
    }
    getType() { return 'undefined'; }
}
export const parseUndefined = new ParserUndefined();
class ParserNull extends Parser {
    parse(ctx, v) {
        if (v !== null) {
            throw new ParseError(ctx, 'expected null, got ' + typeof (v) + ' with value ' + v);
        }
        return null;
    }
    getType() { return 'null'; }
}
export const parseNull = new ParserNull();
export const parseNullAsUndefined = parseTransform(parseNull, _a => undefined);
class ParserBoolean extends Parser {
    parse(ctx, v) {
        if (typeof v !== 'boolean') {
            throw new ParseError(ctx, 'expected boolean, got ' + typeof (v) + ' with value ' + v);
        }
        return v;
    }
    getType() { return 'boolean'; }
}
export const parseBoolean = new ParserBoolean();
class ParserNumber extends Parser {
    parse(ctx, v) {
        if (typeof v !== 'number') {
            throw new ParseError(ctx, 'expected number, got ' + typeof (v) + ' with value ' + v);
        }
        return v;
    }
    getType() { return 'number'; }
}
export const parseNumber = new ParserNumber();
class ParserString extends Parser {
    parse(ctx, v) {
        if (typeof v !== 'string') {
            throw new ParseError(ctx, 'expected string, got ' + typeof (v) + ' with value ' + v);
        }
        return v;
    }
    getType() { return 'number'; }
}
export const parseString = new ParserString();
class ParserDate extends Parser {
    parse(ctx, v) {
        const s = new ParserString().parse(ctx, v);
        const d = new Date(s);
        if (d == null) {
            throw new ParseError(ctx, 'expected date, got ' + s);
        }
        return d;
    }
    getType() { return 'Date'; }
}
export const parseDate = new ParserDate();
class ParserInteger extends Parser {
    parse(ctx, v) {
        const n = new ParserNumber().parse(ctx, v);
        if (!Number.isInteger(n)) {
            throw new ParseError(ctx, 'expected integer, got ' + n.toString());
        }
        return n;
    }
    getType() { return 'Integer'; }
}
export const parseInteger = new ParserInteger();
class ParserHex16Bits extends Parser {
    parse(ctx, v) {
        const s = new ParserString().parse(ctx, v);
        if (!/^([a-z0-9]{4})$/.test(s)) {
            throw new ParseError(ctx, 'expected string with hex digits, got ' + s);
        }
        return parseInt(s, 16);
    }
    getType() { return 'Hex16Bits'; }
}
export const parseHex16Bits = new ParserHex16Bits();
class ParserObject extends Parser {
    constructor(objName, objParser) {
        super();
        this.objName = objName;
        this.objParser = objParser;
    }
    parse(ctx, input) {
        if (typeof input === 'object' && input !== null) {
            ctx = ctx.addClass(this.objName);
            const obj = {};
            for (const key of Object.keys(this.objParser)) {
                const val = this.objParser[key].parse(ctx.addClassField(key), input[key]);
                if (val !== undefined) {
                    obj[key] = val;
                }
            }
            return obj;
        }
        else {
            throw new ParseError(ctx, 'expected object ' + this.objName + ', got ' + (input == null ? 'null' : typeof (input)));
        }
    }
    getType() { return this.objName; }
}
export function parseObject(objName, objParser) {
    return new ParserObject(objName, objParser);
}
export function parseObjectOrNull(objName, objParser) {
    return parseOr(parseNullAsUndefined, parseObject(objName, objParser));
}
class ParserArray extends Parser {
    constructor(parser) {
        super();
        this.parser = parser;
    }
    parse(ctx, v) {
        if (Array.isArray(v)) {
            let res = new Array();
            v.forEach((x, i) => {
                res.push(this.parser.parse(ctx.addArrayIndex(i), x));
            });
            return res;
        }
        else {
            throw new ParseError(ctx, 'expected array, got ' + typeof (v));
        }
    }
    getType() { return this.parser.getType() + '[]'; }
}
export function parseArray(parser) {
    return new ParserArray(parser);
}
// Same as ParserArray but logs an error and continue with next item
// in the array after a failure.
class ParserArrayLogFailed extends Parser {
    constructor(parser) {
        super();
        this.parser = parser;
    }
    parse(ctx, v) {
        if (Array.isArray(v)) {
            let res = new Array();
            v.forEach((x, i) => {
                try {
                    res.push(this.parser.parse(ctx.addArrayIndex(i), x));
                }
                catch (e) {
                    if (e instanceof ParseError) {
                        console.log(e.ctx.str + ' ' + e.message);
                    }
                    else {
                        throw e;
                    }
                }
            });
            return res;
        }
        else {
            throw new ParseError(ctx, 'expected array, got ' + typeof (v));
        }
    }
    getType() { return this.parser.getType() + '[]'; }
}
export function parseArrayLogFailed(parser) {
    return new ParserArrayLogFailed(parser);
}
class ParserOr extends Parser {
    constructor(p1, p2) {
        super();
        this.p1 = p1;
        this.p2 = p2;
    }
    parse(ctx, v) {
        try {
            return this.p1.parse(ctx, v);
        }
        catch (e) {
            if (e instanceof ParseError) {
                try {
                    return this.p2.parse(ctx, v);
                }
                catch (e2) {
                    if (e2 instanceof ParseError) {
                        throw new ParseError(ctx, 'Could not parse ' + this.getType());
                    }
                    else {
                        throw e2;
                    }
                }
            }
            else {
                throw e;
            }
        }
    }
    getType() { return this.p1.getType() + '|' + this.p2.getType(); }
}
export function parseOr(p1, p2) {
    return new ParserOr(p1, p2);
}
// This could be implemented using ParserOr() but then a parse error
// would be reported as: "could not parse undefined|A" which isn't helpful
//
// We want to return the failure from A if it fails.
class ParserOpt extends Parser {
    constructor(p1) {
        super();
        this.p1 = p1;
    }
    parse(ctx, v) {
        if (v === undefined) {
            return undefined;
        }
        else {
            return this.p1.parse(ctx, v);
        }
    }
    getType() { return '?' + this.p1.getType(); }
}
export function parseOpt(p) {
    return new ParserOpt(p);
}
class ParserNullOpt extends Parser {
    constructor(p1) {
        super();
        this.p1 = p1;
    }
    parse(ctx, v) {
        if (v === undefined || v === null) {
            return undefined;
        }
        else {
            return this.p1.parse(ctx, v);
        }
    }
    getType() { return '?' + this.p1.getType(); }
}
export function parseNullOpt(p) {
    return new ParserNullOpt(p);
}
class ParserAnd extends Parser {
    constructor(p1, p2) {
        super();
        this.p1 = p1;
        this.p2 = p2;
    }
    parse(ctx, v) {
        const a = this.p1.parse(ctx, v);
        const b = this.p2.parse(ctx, v);
        return Object.assign(Object.assign({}, a), b);
    }
    getType() { return this.p1.getType() + '&' + this.p2.getType(); }
}
export function parseAnd(p1, p2) {
    return new ParserAnd(p1, p2);
}
class ParserLiteral extends Parser {
    constructor(lit) {
        super();
        this.lit = lit;
    }
    parse(ctx, v) {
        if (this.lit === v) {
            return this.lit;
        }
        else {
            throw new ParseError(ctx, 'Wrong literal, expected ' + this.lit + ' got ' + v);
        }
    }
    getType() { return 'literal'; }
}
export function parseLiteral(lit) {
    return new ParserLiteral(lit);
}
class ParserEnum extends Parser {
    constructor(values) {
        super();
        this.values = values;
    }
    parse(ctx, v) {
        if (typeof v === 'string') {
            for (var i = 0; i < this.values.length; i++) {
                if (this.values[i] === v) {
                    return this.values[i];
                }
            }
            throw new ParseError(ctx, 'Invalid enum value "' + v + '" must be one of: ' + this.values.toString());
        }
        else
            throw new ParseError(ctx, 'Invalid enum value "' + v + '" must be a string');
    }
    getType() { return 'enum(' + this.values.toString() + ')'; }
}
export function parseEnum(values) {
    return new ParserEnum(values);
}
class ParserAndThen extends Parser {
    constructor(parserA, andThenParserB) {
        super();
        this.parserA = parserA;
        this.andThenParserB = andThenParserB;
    }
    parse(ctx, input) {
        const a = this.parserA.parse(ctx, input);
        return this.andThenParserB(a).parse(ctx, input);
    }
    getType() { return 'AndThen'; }
}
export function parseAndThen(parserA, andThenParserB) {
    return new ParserAndThen(parserA, andThenParserB);
}
class ParserPipe extends Parser {
    constructor(parserA, parserB) {
        super();
        this.parserA = parserA;
        this.parserB = parserB;
    }
    parse(ctx, input) {
        const a = this.parserA.parse(ctx, input);
        return this.parserB.parse(ctx, a);
    }
    getType() { return this.parserB.getType(); }
}
export function parsePipe(parserA, parserB) {
    return new ParserPipe(parserA, parserB);
}
class ParserDiscriminatorField extends Parser {
    constructor(discriminatorField) {
        super();
        this.discriminatorField = discriminatorField;
    }
    parse(ctx, input) {
        if (typeof input === 'object' && input !== null) {
            if (this.discriminatorField in input) {
                if (typeof input[this.discriminatorField] === 'string') {
                    return input[this.discriminatorField];
                }
                else {
                    throw new ParseError(ctx, 'Discriminator field ' + this.discriminatorField + ' must be string, was ' + typeof input[this.discriminatorField]);
                }
            }
            else {
                throw new ParseError(ctx, 'Missing discriminator field ' + this.discriminatorField + ' in object ' + input);
            }
        }
        else {
            throw new ParseError(ctx, 'expected object, got ' + (input == null ? 'null' : typeof (input)));
        }
    }
    getType() { return 'string'; }
}
export function parseDiscriminatorField(field) {
    return new ParserDiscriminatorField(field);
}
export function parseDiscriminator(discriminatorParser, variants) {
    return parseAndThen(discriminatorParser, (kind) => {
        var _b;
        return (_b = variants[kind]) !== null && _b !== void 0 ? _b : parseError('invalid discriminator value ' + kind.toString());
    });
}
class ParserValidate extends Parser {
    constructor(parserA, validate) {
        super();
        this.parserA = parserA;
        this.validate = validate;
    }
    parse(ctx, input) {
        const a = this.parserA.parse(ctx, input);
        const error = this.validate(a);
        if (error === null) {
            return a;
        }
        else {
            throw new ParseError(ctx, error);
        }
    }
    getType() { return 'Validate'; }
}
export function parseValidate(parserA, validate) {
    return new ParserValidate(parserA, validate);
}
export function parse(p, value) {
    try {
        return p.parse(new ParseContext(''), value);
    }
    catch (e) {
        if (e instanceof ParseError) {
            throw new Error(e.ctx.str + ': ' + e.message);
        }
        else {
            throw e;
        }
    }
}
