import { parseObject, parseString, parseInteger, parseTransform, parseEnum } from '../../util/parse';
export const parseNeighborDeviceType = parseEnum(['coordinator', 'router', 'end device', 'reserved']);
export const parseNeighborPermitJoining = parseEnum(['yes', 'no', 'unknown1', 'unknown2']);
export const parseNeighborRxWhenIdle = parseEnum(['on', 'off', 'unknown1', 'unknown2']);
export const parseNeighborRelationship = parseEnum(['parent', 'child', 'sibling', 'none', 'previous child', 'unknown1', 'unknown2', 'unknown3']);
export const parseNeighbor = parseTransform(parseObject('Neighbor', {
    depth: parseInteger,
    device_type: parseNeighborDeviceType,
    permit_joining: parseNeighborPermitJoining,
    rx_when_idle: parseNeighborRxWhenIdle,
    relationship: parseNeighborRelationship,
    ieee: parseString,
    nwk: parseInteger,
    epan: parseString,
    lqi: parseInteger
}), o => ({
    depth: o.depth,
    deviceType: o.device_type,
    permitJoining: o.permit_joining,
    rxWhenIdle: o.rx_when_idle,
    relationship: o.relationship,
    ieee: o.ieee,
    nwk: o.nwk,
    extendedPAN: o.epan,
    lqi: o.lqi
}));
