import { parseObject, parseOr, parseOpt, parseHex16Bits, parseInteger, parseString } from '../util/parse';
const parseAddressGroup = parseObject('AddressGroup', { group: parseHex16Bits });
const parseAddressNWK = parseObject('AddressNWK', { nwk: parseInteger, endpoint: parseOpt(parseInteger) });
const parseAddressIEEE = parseObject('AddressIEEE', { ieee: parseString, endpoint: parseOpt(parseInteger) });
const parseAddressNWKIEEE = parseObject('AddressNWKIEEE', { nwk: parseInteger, ieee: parseString, endpoint: parseOpt(parseInteger) });
export const parseAddress = parseOr(parseAddressGroup, parseOr(parseAddressNWKIEEE, parseOr(parseAddressNWK, parseAddressIEEE)));
export function isAddressGroup(obj) {
    return obj.group !== undefined;
}
export function isAddressIEEE(obj) {
    return obj.ieee !== undefined && obj.nwk === undefined;
}
export function isAddressNWK(obj) {
    return obj.ieee === undefined && obj.nwk !== undefined;
}
export function isAddressNWKIEEE(obj) {
    return obj.ieee !== undefined && obj.nwk !== undefined;
}
