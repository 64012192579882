import { parseInteger, parseString, parseObject, parseObjectOrNull, parseArray, parseDate, parseOr, parseTransform } from '../../util/parse';
import { parseAddress } from '../../zigbee/address';
export function compareBindingTableSource(s1, s2) {
    return s1.cluster == s2.cluster && s1.endpoint == s2.endpoint && s1.ieee == s2.ieee;
}
const parseSource = parseObject('source', {
    cluster: parseInteger,
    endpoint: parseInteger,
    ieee: parseString
});
const parseBindingTableItemWithDestination = parseObject('BindingTableItem', {
    destination: parseAddress,
    source: parseSource
});
const parseBindingTableItemWithoutDestinationWithDestGroup = parseTransform(parseObject('BindingTableItem', {
    src_cluster: parseInteger,
    src_ep: parseInteger,
    src_ieee: parseString,
    dest_group: parseInteger
}), o => ({
    destination: { group: o.dest_group },
    source: {
        cluster: o.src_cluster,
        endpoint: o.src_ep,
        ieee: o.src_ieee,
    }
}));
const parseBindingTableItemWithoutDestinationWithDestEp = parseTransform(parseObject('BindingTableItem', {
    src_cluster: parseInteger,
    src_ep: parseInteger,
    src_ieee: parseString,
    dest_ep: parseInteger,
    dest_ieee: parseString,
}), o => ({
    destination: { ieee: o.dest_ieee,
        endpoint: o.dest_ep },
    source: {
        cluster: o.src_cluster,
        endpoint: o.src_ep,
        ieee: o.src_ieee,
    }
}));
export const parseBindingTableItem = parseOr(parseBindingTableItemWithDestination, parseOr(parseBindingTableItemWithoutDestinationWithDestGroup, parseBindingTableItemWithoutDestinationWithDestEp));
export const parseBindingTableOld = parseTransform(parseArray(parseBindingTableItem), items => ({ fetched_ts: new Date(0),
    items }));
/*
export const parseBindingTableNew: Parser<BindingTable | undefined> =
    parseObjectOrNull('BindingTable',  part => {
        const fetched_ts = part.req('fetched_ts', parseDate);
        const items = part.req('items', parseArray(parseBindingTableItem));
        return { fetched_ts, items };
    });
*/
export const parseBindingTableNew = parseObjectOrNull('BindingTableNew', {
    fetched_ts: parseDate,
    items: parseArray(parseBindingTableItem)
});
export const parseBindingTable = parseOr(parseBindingTableNew, parseBindingTableOld);
